<template>
<v-app>
   <!-- alert begin -->
       <div>
      <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <!-- alert end  -->

    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <v-card class="mx-auto my-12">
          <div class="mb-8 mt-8 text-center">
            <h1>Requisition</h1>
          </div>
          <v-form ref="requisition" v-model="valid">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6">
                  <AutocompleteField :items="jobTitles"
                                     v-model="formData.jobTitle"
                                     :label="'Job Title/Position'"
                                     :itemText="'name'"
                                     :itemValue="'id'" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <TextField :type="'text'" v-model="formData.jobDescription" :label="'Brief Job Description'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <AutocompleteField :items="divisions"
                                     v-model="formData.division"
                                     :label="'Division'"
                                     :itemText="'divisionName'"
                                     :itemValue="'id'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <AutocompleteField :items="departments"
                                     v-model="formData.department"
                                     :label="'Department'"
                                     :itemText="'name'"
                                     :itemValue="'id'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <TextField :type="'text'" v-model="formData.businessJustification"
                              :label="'Business Justification'"></TextField>
                </v-col>
                <v-col v-if="getUserRole('HRCLU') | getUserRole('SCM')" cols="12" sm="6" md="6">

                  <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="On Behalf Of"
                              v-model="personnelSearch"
                              :append-icon="'mdi-magnify'"
                              @click:append="perSearch = true"
                              @click.prevent="perSearch = true" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <AutocompleteField :items="chargeCode"
                                     v-model="formData.CostCentre"
                                     :label="'Charge Code'"
                                     :itemText="'costCenter'"
                                     :itemValue="'costCenterDesc'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <TextField :type="'date'" v-model="formData.DateNeeded" :label="'Date Needed'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <TextField :type="'text'" v-model="formData.education" :label="'Education'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <TextField :type="'text'" v-model="formData.experience" :label="'Experience'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SelectField :items="skillLevel"
                               v-model="formData.skillLevel"
                               :label="'Skill Level'"
                               :itemText="'description'"
                               :itemValue="'name'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SelectField :items="RequisitionType"
                               v-model="formData.RequisitionType"
                               :label="'Requisition Type'"
                               :itemText="'description'"
                               :itemValue="'name'" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SelectField :items="requisitionProcess"
                               v-model="formData.requisitionProcess"
                               :label="'Requisition Process'"
                               :itemText="'description'"
                               :itemValue="'name'" />
                </v-col>
              </v-row>
              </v-card-text>
          </v-form>
        </v-card>
      </div>
    </div>
    <v-row>
      <v-col cols="9 mt-5" class="d-flex justify-end">

          <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="dialog = true"
          :disabled="!valid"
          :isLoading="isLoading"
          class="mr-4"
        />
        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

      </v-col>

    </v-row>
    <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel ($event)"  @close="perSearch = false" :type="'employee'" :title="'On Behalf Of'"/>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
</v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import AutocompleteField from '@/components/ui/form/AutocompleteField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { nmaIntegrationService } from '@/services'
import { CREATE_REQUISITION, FETCH_JOBTITLE, FETCH_DEPARTMENT } from '@/store/action-type'

export default {
  components: {
    TextField,
    SelectField,
    AutocompleteField,
    Button,
    PersonnelSearch,
    ConfirmationDialog
  },
  data () {
    return {
      valid: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      dialog: false,
      isLoading: false,
      formData: {
        jobTitle: '',
        DateNeeded: '',
        department: '',
        CostCentre: '',
        position: '',
        education: '',
        experience: '',
        skillLevel: '',
        RequisitionType: '',
        requisitionProcess: '',
        businessJustification: '',
        division: '',
        jobDescription: ''
      },
      chargeCode: [],
      department: [],
      // requisitionProcess: [],
      jobTitle: [],
      divisions: [],
      skillLevel: [
        {
          name: 'HighLevel',
          description: 'High Level'
        },
        {
          name: 'LowLevel',
          description: 'Low Level'
        }
      ],
      RequisitionType: [
        {
          name: 'Addition',
          description: 'Addition'
        },
        {
          name: 'Replacement',
          description: 'Replacement'
        },
        {
          name: 'Renewal',
          description: 'Renewal'
        }
      ],
      requisitionProcess: [
        {
          name: 'Internal',
          description: 'Internal'
        },
        {
          name: 'External',
          description: 'External'
        }
      ],
      loadingPersonnelData: false,
      personnelSearch: '',
      personnelEmail: '',
      search: '',
      perSearch: false

    }
  },
  computed: {
    jobTitles () {
      return this.$store.getters.jobTitles
    },
    departments () {
      return this.$store.getters.departments
    },
    currentUser () {
      console.log('Role', this.$store.getters.users)
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    getUserRole (role) {
      if (this.currentUser.roles.includes(role)) {
        return true
      } else {
        return false
      }
    },
    loadChargeCode () {
      nmaIntegrationService.getAllChargeCodes().then(result => {
        this.chargeCode = result.data
      }).catch(() => {
        this.showAlertMessage('Fail to load charge code', 'error')
      })
    },
    division () {
      nmaIntegrationService.getAllOrgId().then(result => {
        this.divisions = result.data
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Fail to load division', 'error')
      })
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },

    selectPersonnel (item) {
      this.search = ''
      this.personnelSearch = item.name
      this.personnelEmail = item.emailAddress
    },

    onSave () {
      this.isLoading = true
      const data = {
        DepartmentId: this.formData.department,
        JobTitleId: this.formData.jobTitle,
        CostCentre: this.formData.CostCentre,
        DateNeeded: this.formData.DateNeeded,
        Education: this.formData.education,
        Experience: this.formData.experience,
        SkillLevel: this.formData.skillLevel,
        RequisitionType: this.formData.RequisitionType,
        RequisitionProcess: this.formData.requisitionProcess,
        BriefJobDescription: this.formData.jobDescription,
        BusinessJustification: this.formData.businessJustification,
        OrgId: this.formData.division,
        OnBehalfOfEmail: this.personnelEmail
      }

      this.$store.dispatch(CREATE_REQUISITION, data).then((result) => {
        console.log(result);
        this.showAlertMessage('Requisition creation successful', 'success')
      }).catch((error) => {
        console.log('Error: ', error.message)
        this.showAlertMessage('Requisition creation successful', 'success')
        this.isLoading = false
        this.dialog = false
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.requisition.reset()
      this.formData.jobTitle = ''
      this.formData.DateNeeded = ''
      this.formData.department = ''
      this.formData.CostCentre = ''
      this.formData.position = ''
      this.formData.education = ''
      this.formData.experience = ''
      this.formData.skillLevel = ''
      this.formData.RequisitionType = ''
    }

  },
  mounted () {
    this.loadChargeCode()
    this.division()

    this.$store
      .dispatch(FETCH_JOBTITLE)
      .then(() => { })
      .catch((error) => {
        if (error) {
          this.showAlertMessage('There are no items to display', 'warning')
        }
      })

    this.$store
      .dispatch(FETCH_DEPARTMENT)
      .then(() => { })
      .catch((error) => {
        if (error) {
          this.showAlertMessage('There are no items to display', 'warning')
        }
      })
  }
}
</script>
<style scoped>

</style>
